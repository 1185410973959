<template>
  <v-container fluid>
    <Column />
  </v-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import Column from '@/components/Column.vue'

export default defineComponent({
  name: 'ColumnCreate',
  components: { Column },
})
</script>
